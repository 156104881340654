import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  data() {
    var validateEmail = (rule, value, callback) => {
      var emailReg =
        /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      let emails = this.getAllContacts.data.map((user) => user.email);
      if (
        this.selectedUser &&
        this.selectedUser.email &&
        this.selectedUser.email == value
      ) {
        emails = [];
      }
      if (value === "") {
        callback(new Error("Email is Required"));
      } else if (!emailReg.test(value)) {
        callback(new Error("Email is not valid"));
      } else if (emails.indexOf(value) != -1) {
        callback(new Error("Email already existed"));
      } else {
        callback();
      }
    };
    return {
      newUserFormRules: {
        first_name: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Last name is required", trigger: "blur" },
        ],
        company_name: [
          { required: false, message: "Company is required", trigger: "blur" },
        ],
        phone: [
          { required: false, message: "Phone is required", trigger: "blur" },
        ],

        contact_type: [
          {
            required: true,
            message: "Contact Type is required",
            trigger: "blur",
          },
        ],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
      },
      colors: [
        "#FF9FF3",
        "#FECA57",
        "#A3CB38",
        "#1DD1A1",
        "#009432",
        "#54A0FF",
        "#0652DD",
        "#5F27CD",
        "#F368E0",
        "#00D2D3",
        "#01A3A4",
        "#FF9F43",
        "#1B1464",
        "#006266",
        "#EE5A24",
        "#833471",
        "#EA2027",
        "#9980FA",
        "#E15F41",
        "#786FA6",
        "#E66767",
        "#303952",
        "#218C74",
        "#706FD3",
      ],
      defined_colors: ["#FF495C", "#3777FF", "#EE964B", "#03CEA4"],
      relationshipEntities: [],
    };
  },
  computed: {
    ...mapGetters("menuManagementV2", [
      "getEntityMenu",
      "getMenuManagementAddStatus",
    ]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("auth", ["getActiveContactType"]),
    ...mapGetters("entities", ["getEntityDataById"]),
  },
  methods: {
    getUserName(id) {
      let data = this.getUserTypes.data.find((x) => x._id == id);
      return data.name || "";
    },
    getRandomColor(existColors) {
      //  console.log(existColors)
      // console.log(index)
      let filterdColors = [];
      let indx = -1;

      if (existColors && existColors.length) {
        // getting only non existed colors
        filterdColors = this.defined_colors.filter(
          (color) => existColors.indexOf(color) == -1
        );
        // console.log(filterdColors)
        if (filterdColors && filterdColors.length) {
          indx = Math.floor(Math.random() * filterdColors.length);
        } else {
          filterdColors = [];
          filterdColors = this.colors.filter(
            (color) => existColors.indexOf(color) == -1
          );
          // getting randam index of array based on length
          indx = Math.floor(Math.random() * filterdColors.length);
        }
      } else {
        filterdColors = this.defined_colors;
      }

      return filterdColors[indx];
    },
    //application user default methods
    async checkAndUpdateData(redirect_url = "") {
      if (this.getActiveContactType?.contact_type?._id) {
        let id = this.getActiveContactType?.contact_type?._id;
        let dataId = this.getActiveContactType?.account_data_id;
        await Promise.all([
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: id,
          }),
          this.$store.dispatch("menuManagementV2/fetchMenuByEntity", id),
          this.$store.dispatch(
            "applicationUsersPermission/fetchPermissionsEntityById",
            { id: id }
          ),
        ]);
        if (this.getEntityRelationships && this.getEntityRelationships.length) {
          this.setRelationshipPermissions(id);
        }
        let filterIds = [];
        this.getEntityMenu?.menu_list.forEach(item => {
          if (item?.selected_entity_filter) {
            filterIds.push(item.selected_entity_filter);
          }
        });
        if (filterIds?.length) {
          let params = {
            entity_id: id,
            entity_data_id: dataId,
            filterIds: filterIds
          }
          const response = await postAPICall(
            "POST",
            "/entity/filtered-menu",
            params
          );
          if (response?.data) {
            let newMenu = [];
            this.getEntityMenu?.menu_list.forEach(item => {
              if(item?.selected_entity_filter){
                if(response?.data[item.selected_entity_filter]) {
                  newMenu.push(item);
                }
              } else {
                newMenu.push(item);
              }
            })
            this.$store.commit("menuManagementV2/setEntityMenu", {
              ...this.getEntityMenu,
              ...{
                ['menu_list']: newMenu
              }
            }, {root:true});
          }
        }
        if (!this.getEntityMenu) {
          this.setStandardMenu(id);
        } else {
          if (this.$route.path == "/ap/dashboard") {
            window.location.reload();
          } else {
            if (redirect_url) {
              this.$router.push({ path: redirect_url });
            } else {
              window.location.replace(window.location.origin + "/ap/dashboard");
            }
          }
        }
      } else {
        if (this.$route.path == "/ap/dashboard") {
          window.location.reload();
        } else {
          window.location.replace(window.location.origin + "/ap/dashboard");
        }
      }
    },
    async setStandardMenu(id) {
      let data = [];
      data.push({
        children: [],
        title: "Dashboard",
        feature: "dashboard",
        category: "",
        icon: "dashboard.svg",
        permission: "",
        isGroup: "",
        type: "Individual",
      });
      data.push({
        children: [],
        title: "My Documents",
        feature: "my_documents",
        category: "",
        icon: "File.svg",
        permission: "",
        isGroup: "",
        type: "Individual",
      });
      data.push({
        children: [],
        title: "My Workflows",
        feature: "my_workflows",
        category: "",
        type: "Individual",
        icon: "Workflows.svg",
        isGroup: "",
      });
      data.push({
        title: "My form builders",
        feature: "my_form_builders",
        category: "",
        parent: "",
        icon: "dashboard.svg",
        children: [],
        permission: "",
        isGroup: "",
        type: "Individual",
      });
      if (this.relationshipEntities && this.relationshipEntities.length) {
        this.relationshipEntities.forEach((e) => {
          data.push({
            entity_type: "RELATIONAL_ENTITY",
            children: [],
            title: "My " + (e && e.name ? e.name : "data"),
            feature: "entities",
            category: "",
            icon: "dashboard.svg",
            permission: "",
            isGroup: "",
            relational_entity: e.id,
            type: "Individual",
          });
        });
      }
      let params = {
        entity: id,
        menu_list: data,
        type: "USER",
      };
      await this.$store.dispatch("menuManagementV2/addMenuManagement", params);
      if (this.getMenuManagementAddStatus) {
        await this.$store.dispatch("menuManagementV2/fetchMenuByEntity", id);
        if (this.$route.path == "/ap/dashboard") {
          window.location.reload();
        } else {
          window.location.replace(window.location.origin + "/ap/dashboard");
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error while setting your account",
        });
      }
    },
    setRelationshipPermissions(id) {
      this.getEntityRelationships.forEach((el) => {
        if (el?.child_entity_id == id) {
          this.relationshipEntities.push({
            id: el.parent_entity_id,
            name: el.parent_relation_title,
          });
        } else if (id == el?.parent_entity_id) {
          this.relationshipEntities.push({
            id: el.child_entity_id,
            name: el.child_relation_title,
          });
        }
      });
    },
  },
};
